<template>
	<w-loadable :items="lessons" skelet="spinner" class="container" :messageEmpty="noItemsMessage">
		<ion-row>
			<ion-col size="6" size-sm="4" size-md="3" size-xl="2" v-for="lesson in lessons" :key="lesson.id" class="task" :class="{'approved': lesson.verification_status == 'verified'}">
				<ion-card v-if="lesson.verification_status == 'pending'" @click="showProfileModal(lesson)">
					<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
						<img v-if="lesson.content_thumbnail" :src="lesson.content_thumbnail.path">
						<img v-else src="@/plugins/lib@enum/themes/img/placeholder-img.jpg">
						<div class="overlay d-flex flex-direction-column ion-align-items-center ion-justify-content-center">
							<ion-icon :icon="hourglassOutline"></ion-icon>
							<p class="ion-no-margin large">Čaká na overenie</p>
						</div>
					</ion-card-header>

					<div class="info-wrapper width-100">
						<!-- <ion-card-title>{{ lesson.title }}</ion-card-title> -->
						<ion-card-title>{{ lesson.name }}</ion-card-title>
					</div>
				</ion-card>

				<ion-card v-if="lesson.verification_status == 'verified'">
					<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
						<img v-if="lesson.content_thumbnail" :src="lesson.content_thumbnail.path">
						<img v-else src="@/plugins/lib@enum/themes/img/placeholder-img.jpg">
						<div class="overlay d-flex flex-direction-column ion-align-items-center ion-justify-content-center">
							<ion-icon :icon="checkmarkDoneCircleOutline"></ion-icon>
							<p class="ion-no-margin large">Overené mentorom</p>
						</div>
					</ion-card-header>

					<div class="info-wrapper width-100">
						<ion-card-title>{{ lesson.title }}</ion-card-title>
						<p>{{ lesson.name }}</p>
					</div>
				</ion-card>
			</ion-col>
		</ion-row>

		<ion-infinite-scroll @ionInfinite="$emit('loadMore', $event)" threshold="10%" v-if="infiniteScroll">
			<ion-infinite-scroll-content loading-spinner="bubbles" />
		</ion-infinite-scroll>	
	</w-loadable >
</template>

<script>
import AProfileModal 		from '@/plugins/lib@profile/profile/a-profile-modal.vue'
import wAxios 				from '@/plugins/w/axios'
import { mapGetters} 		from 'vuex'
import { modalController } 	from '@ionic/core'
import { hourglassOutline, checkmarkDoneCircleOutline } from 'ionicons/icons'

const api = {
	verifications: (id ) => wAxios.get_auth_data(`v1/users/${id}/verifications`)
}

export default {
	props: {
		items: {
			type: Array,
			required: true
		},

		noItemsMessage: {
			type: String,
			default: 'Nothing to display'
		},
		
		infiniteScroll: {
			type: Boolean,
			default: true
		},

		detailsPage: {
			required: false,
			default: '#'
		},
	},

	data() {
		return {
			lessons: null,
			profileModal: null,

			hourglassOutline, 
			checkmarkDoneCircleOutline
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),

		userId() {
			return this.$route.params.id
		}
	},

	created() {
		this._loadLessons()
	},

	methods: {
		async showProfileModal(lesson) {
			this.profileModal = await modalController.create({
				component: AProfileModal,
				componentProps: {
					vm: this,
					lesson: lesson
				},
				cssClass: 'profile-modal',
				swipeToClose: true,
			})

			this.profileModal.present()
			await this.profileModal.onDidDismiss()
			this._loadLessons()
		},

		async _loadLessons() {
			try {
				this.lessons = await api.verifications(this.userId)
				console.log(this.lessons)
			} catch (error) {
				this.lessons = {error: error}
			}
		},
	}
}
</script>