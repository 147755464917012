<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label>Profil</ion-label>
				</ion-title>
				<!-- <ion-title>
					<ion-searchbar class="header-search web-version" placeholder="Vyhľadaj" show-cancel-button="never"></ion-searchbar>
					<ion-label class="mobile-version">Profil</ion-label>
				</ion-title> -->

				<div slot="end" class="user-wrapper" v-if="isLoggedIn" @click="logOut()">
					<ion-icon :icon="logOutOutline" slot="end"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="user-info-wrapper container d-flex ion-align-items-center mt-2 mb-2">
				<img src="../../lib@course/course/img/avatar.png">
				<h3 v-if="user?.name" class="ion-no-margin">{{ user?.name }} (id {{ user.id }})</h3>
				<h3 v-else class="ion-no-margin">Profil</h3>
			</div>

			<ion-segment @ionChange="onChange($event.detail.value)" class="mb-2 container" :value="activeTab">
				<ion-segment-button value="bookmarks">
					<div class="d-flex ion-align-items-center">
						<ion-icon class="bookmarks" :icon="bookmarks"></ion-icon>
						<ion-label class="p-0">Uložené</ion-label>
					</div>
				</ion-segment-button>
				
				<ion-segment-button value="like">
					<div class="d-flex ion-align-items-center">
						<ion-icon :icon="heart"></ion-icon>
						<ion-label class="p-0">Lajknuté</ion-label>
					</div>
				</ion-segment-button>

				<ion-segment-button value="done">
					<div class="d-flex ion-align-items-center">
						<ion-icon :icon="checkmarkDoneCircle"></ion-icon>
						<ion-label class="p-0">Dokončené</ion-label>
					</div>
				</ion-segment-button>

				<ion-segment-button value="tasks">
					<div class="d-flex ion-align-items-center">
						<ion-icon :icon="school"></ion-icon>
						<ion-label class="p-0">Overené</ion-label>
					</div>
				</ion-segment-button>

				<ion-segment-button value="projects" class="projects">
					<div class="d-flex ion-align-items-center">
						<ion-icon :icon="settingsSharp"></ion-icon>
						<ion-label class="p-0">Projekty</ion-label>
					</div>
				</ion-segment-button>
			</ion-segment>

			<div v-if="activeTab == 'bookmarks'">
				<A-bookmarked noItemsMessage="Žiadne bookmarknuté videá alebo kurzy" :infiniteScroll="false" detailsPage="feed-profile-videos" class="bookmarked" />
			</div>
			<div v-if="activeTab == 'like'">
				<A-liked noItemsMessage="Žiadne likenuté videá" :infiniteScroll="false" detailsPage="feed-profile-videos" class="liked" />
			</div>
			<div v-if="activeTab == 'done'">
				<A-checkmarked noItemsMessage="Žiadne checkmarknuté kurzy alebo lekcie" :infiniteScroll="false" detailsPage="feed-profile-videos" class="checkmarked" />
			</div>
			<div v-if="activeTab == 'tasks'">
				<A-verifications noItemsMessage="Žiadne videá na overenie" :infiniteScroll="false" detailsPage="feed-profile-videos" class="verifications" />
			</div>
			<div v-if="activeTab == 'projects'">
				<div class="d-flex ion-justify-content-center container mb-2 mt-5">
					<p class="ml-1 mr-1 mb-1 large clickable"><a @click="$router.push({name: 'projects'})"><b>Všetky projekty</b></a></p>
					<p class="ml-1 mr-1 mb-1 large clickable"><a @click="$router.push({name: 'my-projects'})"><b>Moje Projekty</b></a></p>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import ABookmarked			from '@/plugins/lib@profile/profile/components/a-bookmarked.vue'
import ALiked				from '@/plugins/lib@profile/profile/components/a-liked.vue'
import ACheckmarked			from '@/plugins/lib@profile/profile/components/a-checkmarked.vue'
import AVerifications		from '@/plugins/lib@profile/profile/components/a-verifications.vue'
import wAxios 				from '@/plugins/w/axios'
import { bookmarks, heart, checkmarkDoneCircle, school, eyeOutline, hourglassOutline, checkmarkDoneCircleOutline, logOutOutline, settingsSharp } from 'ionicons/icons'
import { mapGetters} 		from 'vuex'

const api = {
	userInfo: (user_id) => wAxios.get_auth_data(`v1/users/${user_id}`),
}

export default {
	components: {
		ABookmarked,
		ALiked,
		ACheckmarked,
		AVerifications
	},
	data() {
		return {
			bookmarks,
			heart,
			checkmarkDoneCircle,
			school,
			eyeOutline,
			hourglassOutline,
			checkmarkDoneCircleOutline,
			logOutOutline,
			settingsSharp,
			activeTab: 'bookmarks',
			user: null
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),

		userId() {
			return this.$route.params.id
		}
	},

	async created() {
		try {
			this.user = await api.userInfo(this.userId)
			console.log(this.user)
		} catch (error) {
			this.user = {error: error}
		}
	},

	methods: {
		async logOut() {
			await this.$store.dispatch('wAuth/logout')
			this.$router.replace('/login')
		},

		onChange(value) {
			if (value == 'bookmarks') {
				this.activeTab = 'bookmarks'
			} else if (value == 'like') {
				this.activeTab = 'like'
			} else if (value == 'done') {
				this.activeTab = 'done'
			} else if (value == 'tasks') {
				this.activeTab = 'tasks'
			} else if (value == 'projects') {
				this.activeTab = 'projects'
			}
		},
	}
}
</script>

<style lang="sass" scoped>
@import '@/plugins/lib@user/custom/lib@user-profil.sass'

.bookmarked,
.liked,
.checkmarked,
.verifications
	::v-deep
		@extend .libuser-profil

.user-info-wrapper
	img
		width: 40px
		height: 40px
		border-radius: 50%
		margin: 0 30px 0 10px

ion-segment
	padding: 0 5px
	ion-segment-button
		--border-radius: 50px
		padding: 5px 0
		min-width: 30px
		--padding-start: 1px
		--padding-end: 1px

		&.projects
			--indicator-color: var(--ion-color-danger)
			--color: var(--ion-color-danger)

		ion-icon
			width: 28px
			height: 28px

			&.bookmarks
				width: 24px
				height: 24px

		ion-label
			display: none

		ion-icon
			width: 20px !important
			height: 20px !important

@media only screen and (min-width: 767px)
	ion-segment
		width: 750px
		margin: 0 auto 10px

		ion-icon
			margin-right: 5px

		ion-label
			display: block !important
			
@media only screen and (max-width: 1199px)
	.container
		max-width: 800px !important

@media only screen and (max-width: 767px)
	.container
		max-width: 600px !important

</style>
