<template>
	<w-loadable :items="lessons" skelet="spinner" class="container" :messageEmpty="noItemsMessage">
		<ion-row>
			<ion-col size="6" size-sm="4" size-md="3" size-xl="2" v-for="lesson in lessons" :key="lesson.id" class="task">
				<ion-card 
					@click="$router.push(lesson.content_shot ? `/course/${lesson.course.slug}/lesson-feed/${lesson.slug}/${lesson.id}` : `/course/${lesson.course.slug}`)">
					<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
						<img v-if="lesson.content_thumbnail" :src="lesson.content_thumbnail.path">
						<img v-else src="@/plugins/lib@enum/themes/img/placeholder-img.jpg">
					</ion-card-header>

					<div class="info-wrapper width-100">
						<!-- <ion-card-title>{{ lesson.title }}</ion-card-title> -->
						<ion-card-title>{{ lesson.name }}</ion-card-title>
					</div>
				</ion-card>
			</ion-col>
		</ion-row>

		<ion-infinite-scroll @ionInfinite="$emit('loadMore', $event)" threshold="10%" v-if="infiniteScroll">
			<ion-infinite-scroll-content loading-spinner="bubbles" />
		</ion-infinite-scroll>	
	</w-loadable >
</template>

<script>
import wAxios 			from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'

const api = {
	done: (id ) => wAxios.get_auth_data(`v1/users/${id}/lessons?types=done`)
}

export default {
	props: {
		items: {
			type: Array,
			required: true
		},

		noItemsMessage: {
			type: String,
			default: 'Nothing to display'
		},
		
		infiniteScroll: {
			type: Boolean,
			default: true
		},

		detailsPage: {
			required: false,
			default: '#'
		},
	},

	data() {
		return {
			lessons: null,
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),

		userId() {
			return this.$route.params.id
		}
	},

	async created() {
		try {
			this.lessons = await api.done(this.userId)
			console.log(this.lessons)
		} catch (error) {
			this.lessons = {error: error}
		}
	},
}
</script>
