<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>	
				<ion-title class="text-center mb-1">{{ lesson.course.name }}</ion-title>
				<ion-icon class="close-button size-xl" slot="end" :icon="closeOutline" @click="hideModal()"></ion-icon>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-modal-padding">
			<ion-row>
				<ion-col size="6" size-sm="4" class="videos" v-for="video in lesson.video_lessons" :key="video.id">
					<ion-card>
						<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
							<img :src="video.content_thumbnail">
						</ion-card-header>
						
						<div class="info-wrapper width-100">
							<!-- <img src="../../lib@course/course/img/avatar.png" alt=""> -->
							<ion-card-title class="ion-no-margin">{{ video.name }}</ion-card-title>
						</div>
					</ion-card>
				</ion-col>
			</ion-row>
		</ion-content>

		<ion-footer v-if="user.user_role == 'user'">
			<ion-toolbar>
				<div class="width-100 d-flex flex-direction-column ion-align-items-center">
					<ion-button fake color="success" class="button-block">Nahrať video</ion-button>
					<ion-button @click="cancelVerification()" color="danger" class="button-block">Zrušiť žiadosť o overenie</ion-button>
				</div>
			</ion-toolbar>
		</ion-footer>

		<ion-footer v-if="user.user_role == 'mentor'">
			<ion-toolbar>
				<div class="width-100 d-flex flex-direction-column ion-align-items-center">
					<ion-button :disabled="isLoading" @click="confirmVerification()" color="success" class="button-block">Potvrdiť splnenie úlohy</ion-button>
					<ion-spinner v-if="isLoading"></ion-spinner>
				</div>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<script>
import { closeOutline} 		from 'ionicons/icons'
import { modalController } 	from '@ionic/core'
import wAxios 				from '@/plugins/w/axios'
import { mapGetters} 		from 'vuex'

const api = {
	deleteVerification: (id) => wAxios.delete_auth_data(`v1/lessons/${id}/verify`),
	confirmVerification: (userId, lessonId) => wAxios.post_auth_data(`v1/users/${userId}/lessons/${lessonId}/verify`, {status: 'verified'}),
}

export default {
	props: {
		vm: {
			type: Object,
			required: true
		},

		lesson: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			isLoading: false,
			closeOutline
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),

		userId() {
			return this.$route.params.id
		}
	},

	mounted() {
		console.log(this.lesson)
	},

	methods: {
		hideModal() {
			this.vm.profileModal.dismiss()
		},

		async cancelVerification() {
			console.log("test")
			try {
				await api.deleteVerification(this.lesson.id)
				console.log("test")
			} catch (error) {
				console.log(error)
				// this.$wToast.error(error)
			}
			modalController.dismiss()
		},

		async confirmVerification() {
			try {
				this.isLoading = true
				await api.confirmVerification(this.userId, this.lesson.id)
				modalController.dismiss()
			} catch (error) {
				console.log(error)
			}
			this.isLoading = false
		}
	},

	watch: {
		'$route'() {
			this.hideModal()
		}
	}
}
</script>
