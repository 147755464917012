<template class="bookmarked">
	<w-loadable :items="lessons" skelet="spinner" class="container" :messageEmpty="noItemsMessage">
		<ion-row>
			<ion-col size="6" size-sm="4" size-md="3" size-xl="2" v-for="lesson in lessons" :key="lesson.id" :class="{'videos': lesson.content_shot}">
				<ion-card 
					@click="$router.push(lesson.content_shot ? {name: `personal-feed`, params: {userId: userId, type: 'bookmark', videoId: lesson.id}} : `/course/${lesson.course.slug}`)">
					<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
						<img v-if="lesson.content_thumbnail" :src="lesson.content_thumbnail.path ? lesson.content_thumbnail.path : lesson.content_thumbnail">
						<img v-else src="@/plugins/lib@enum/themes/img/placeholder-img.jpg">
						<div v-if="lesson.content_shot" class="overlay d-flex ion-align-items-end">

							<div class="video-stats d-flex ion-justify-content-between">
								<p class="small mb-0">{{ moment(lesson.created_at).format('DD.MM.YYYY') }}</p>

								<div class="d-flex ion-justify-content-between ion-align-items-center">
									<ion-icon :icon="eyeOutline"></ion-icon>
									<p class="small mb-0">{{ lesson.visits }}</p>
								</div>
							</div>

						</div>
					</ion-card-header>

					<div class="info-wrapper width-100">
						<!-- <ion-card-title>{{ lesson.title }}</ion-card-title> -->
						<ion-card-title v-if="!lesson.content_shot">{{ lesson.name }}</ion-card-title>
						<p v-if="lesson.content_shot">#{{ lesson.tags.join(", #") }}</p>

						<div v-if="lesson.content_shot" class="creator d-flex ion-align-items-center">
							<!-- <img src="@/plugins/lib@course/course/img/avatar.png" alt=""> -->
							<p class="ion-no-margin">{{ lesson.content_shot_author }}</p>
						</div>
					</div>
				</ion-card>
			</ion-col>
		</ion-row>

		<ion-infinite-scroll @ionInfinite="$emit('loadMore', $event)" threshold="10%" v-if="infiniteScroll">
			<ion-infinite-scroll-content loading-spinner="bubbles" />
		</ion-infinite-scroll>	
	</w-loadable >
</template>

<script>
import wAxios 			from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'
import moment from 'moment'
import { eyeOutline } from 'ionicons/icons'

const api = {
	bookmarked: (id) => wAxios.get_auth_data(`v1/users/${id}/lessons?types=bookmark`)
}

export default {
	props: {
		items: {
			type: Array,
			required: true
		},

		noItemsMessage: {
			type: String,
			default: 'Nothing to display'
		},
		
		infiniteScroll: {
			type: Boolean,
			default: true
		},

		detailsPage: {
			required: false,
			default: '#'
		},
	},

	data() {
		return {
			eyeOutline,

			lessons: null,
			moment: moment
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),

		userId() {
			return this.$route.params.id
		}
	},

	async created() {
		try {
			this.lessons = await api.bookmarked(this.userId)
			console.log(this.lessons)
		} catch (error) {
			this.lessons = {error: error}
		}
	},
}
</script>