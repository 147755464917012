<template>
	<w-loadable :items="videos" skelet="spinner" class="container" :messageEmpty="noItemsMessage">
		<ion-row>
			<ion-col size="6" size-sm="4" size-md="3" size-xl="2" v-for="video in videos" :key="video.id" :class="{'videos': video.content_shot}">
				<ion-card 
					@click="$router.push({name: `personal-feed`, params: {userId: userId, type: 'like', videoId: video.id}})">
					<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
						<img v-if="video.content_thumbnail" :src="video.content_thumbnail.path ? video.content_thumbnail.path : video.content_thumbnail">
						<img v-else src="@/plugins/lib@enum/themes/img/placeholder-img.jpg">
						<div v-if="video.content_shot" class="overlay d-flex ion-align-items-end">

							<div class="video-stats d-flex ion-justify-content-between">
								<p class="small mb-0">{{ moment(video.created_at).format('DD.MM.YYYY') }}</p>

								<div class="d-flex ion-justify-content-between ion-align-items-center">
									<ion-icon :icon="eyeOutline"></ion-icon>
									<p class="small mb-0">{{ video.visits }}</p>
								</div>
							</div>

						</div>
					</ion-card-header>

					<div class="info-wrapper width-100">
						<!-- <ion-card-title v-if="video.content_shot">{{ video.content_description || video.name }}</ion-card-title> -->
						<p v-if="video.content_shot">#{{ video.tags.join(", #") }}</p>

						<div v-if="video.content_shot" class="creator d-flex ion-align-items-center">
							<!-- <img src="@/plugins/lib@course/course/img/avatar.png" alt=""> -->
							<p class="ion-no-margin">{{ video.content_shot_author }}</p>
						</div>
					</div>
				</ion-card>
			</ion-col>
		</ion-row>

		<ion-infinite-scroll @ionInfinite="$emit('loadMore', $event)" threshold="10%" v-if="infiniteScroll">
			<ion-infinite-scroll-content loading-spinner="bubbles" />
		</ion-infinite-scroll>	
	</w-loadable >
</template>

<script>
import wAxios 			from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'
import moment 			from 'moment'
import { eyeOutline } 	from 'ionicons/icons'

const api = {
	liked: (id ) => wAxios.get_auth_data(`v1/users/${id}/lessons?types=like`)
}

export default {
	props: {
		items: {
			type: Array,
			required: true
		},

		noItemsMessage: {
			type: String,
			default: 'Nothing to display'
		},
		
		infiniteScroll: {
			type: Boolean,
			default: true
		},

		detailsPage: {
			required: false,
			default: '#'
		},
	},

	data() {
		return {
			eyeOutline,

			videos: null,
			moment: moment
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),

		userId() {
			return this.$route.params.id
		}
	},

	async created() {
		try {
			this.videos = await api.liked(this.userId)
			console.log(this.videos)
		} catch (error) {
			this.videos = {error: error}
		}
	},
}
</script>